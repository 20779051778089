import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('@/views/Index'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import('@/views/home/Index'),
            },
        ]
    },
    {
        path: '/registration',
        name: 'Registration',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/registration/registration')
            }
        ]
    },
    {
        path: '/accommodation',
        name: 'Accommodation',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/registration/accommodation')
            }
        ]
    },
    {
        path: '/visa_information',
        name: 'Visa Information',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/registration/visa_information')
            }
        ]
    },
    {
        path: '/onsite_childcare',
        name: 'Onsite Childcare',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/registration/onsite_childcare')
            }
        ]
    },
    {
        path: '/travel_grants',
        name: 'Student Travel Grants',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/registration/travel_grants')
            }
        ]
    },
    {
        path: '/important-dates',
        name: 'Dates',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/important-dates/Index')
            }
        ]
    },
    // todo Organization 开始
    {
        path: '/organisation-committee',
        name: 'Organisation',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/organisation/organising-committee')
            }
        ]
    },
    {
        path: '/reviewer-guidelines',
        name: 'Reviewer Guidelines',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/organisation/reviewer-guidelines')
            }
        ]
    },
    // todo Organization 结束
    {
        path: '/sponsors',
        name: 'Sponsors',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/sponsor/sponsors')
            }
        ]
    },
    {
        path: '/our_sponsors',
        name: 'Our Sponsors',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/sponsor/our_sponsors')
            }
        ]
    },
    {
        path: '/call_for_sponsor_aus',
        name: 'Call for Sponsors',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/sponsor/call_for_sponsor_aus')
            }
        ]
    },
    {
        path: '/keynotes',
        name: 'keynotes',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/keynotes')
            }
        ]
    },
    {
        path: '/accepted-list',
        name: 'accepted-list',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/accepted-list')
            }
        ]
    },
    // {
    //     path: '/program-at-a-glance',
    //     name: 'program-at-a-glance',
    //     component: () => import('@/views/Context'),
    //     children: [
    //         {
    //             path: '/',
    //             component: () => import('@/views/program/program-at-a-glance')
    //         }
    //     ]
    // },
    {
        path: '/full-draft-program',
        name: 'full-draft-program',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/full-draft-program')
            }
        ]
    },
    {
        path: '/tutorials',
        name: 'tutorials',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/tutorials')
            }
        ]
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/panel')
            }
        ]
    },
    {
        path: '/main-track-list',
        name: 'maintracklist',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/main-track-list')
            }
        ]
    },
    {
        path: '/multimedia-grand-challenge',
        name: 'multimedia-grand-challenge',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/multimedia-grand-challenge')
            }
        ]
    },
    {
        path: '/awards',
        name: 'awards',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/awards')
            }
        ]
    },
    // todo calls-for-submission 开始
    {
        path: '/industrial-demo',
        name: 'industrial-demo',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/industrial-demo')
            }
        ]
    },
    // todo calls-for-submission 开始
    {
        path: '/industry-expert-talks',
        name: 'industry-expert-talks',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/industry-expert-talks')
            }
        ]
    },
    {
        path: '/authors-advocate',
        name: 'authors-advocate',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/authors-advocate')
            }
        ]
    },
    {
        path: '/doctoral-cymposium',
        name: 'doctoral-cymposium',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/doctoral-cymposium')
            }
        ]
    },
    {
        path: '/brave-new-ideas-proposals',
        name: 'brave-new-ideas-proposals',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/brave-new-ideas-proposals')
            }
        ]
    },
    {
        path: '/multimedia-grand-challenge-proposals',
        name: 'multimedia-grand-challenge-proposals',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/multimedia-grand-challenge-proposals')
            }
        ]
    },
    {
        path: '/multimedia-grand-challenge-submissions',
        name: 'multimedia-grand-challenge-submissions',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/multimedia-grand-challenge-submissions')
            }
        ]
    },
    {
        path: '/keynotes',
        name: 'keynotes',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/keynotes')
            }
        ]
    },
    {
        path: '/open-source-software-competition',
        name: 'open-source-software-competition',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/open-source-software-competition')
            }
        ]
    },
    {
        path: '/panel-proposals',
        name: 'panel-proposals',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/panel-proposals')
            }
        ]
    },
    {
        path: '/workshop-proposals',
        name: 'workshop-proposals',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/workshop-proposals')
            }
        ]
    },
    {
        path: '/regular-papers',
        name: 'regular-papers',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/regular-papers')
            }
        ]
    },
    {
        path: '/reproducibility-companion-papers',
        name: 'reproducibility-companion-papers',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/reproducibility-companion-papers')
            }
        ]
    },
    {
        path: '/technical-demos-and-videos-program',
        name: 'technical-demos-and-videos-program',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/technical-demos-and-videos-program')
            }
        ]
    },
    {
        path: '/tutorial-proposals',
        name: 'tutorial-proposals',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/tutorial-proposals')
            }
        ]
    },
    {
        path: '/workshop-papers',
        name: 'workshop-papers',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/calls-for-submission/workshop-papers')
            }
        ]
    },
    // todo calls-for-submission 结束
    {
        path: '/contact',
        name: 'Contact Us',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/contact/Index')
            }
        ]
    },
    {
        path: '/visit_victoria',
        name: 'Visit Victoria',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/destination/visit_victoria')
            }
        ]
    },
    {
        path: '/explore_melbourne',
        name: 'Explore Melbourne',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/destination/explore_melbourne')
            }
        ]
    },
    {
        path: '/policies',
        name: 'Policies',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/guideline/policies')
            }
        ]
    },
    {
        path: '/reviewer_guidelines',
        name: 'Reviewer Guidelines',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/guideline/reviewer_guidelines')
            }
        ]
    },
    {
        path: '/volunteer_ac',
        name: 'Call for Volunteer Area Chairs and Reviewers',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/guideline/volunteer_ac')
            }
        ]
    },
    {
        path: '/major_differences',
        name: 'Major Differences: MM24 vs Previous',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/guideline/major_differences')
            }
        ]
    },
    {
        path: '/ac_guidelines',
        name: 'AC and SAC Guidelines',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/guideline/ac_guidelines')
            }
        ]
    },
    {
        path: '/presenter_guidelines',
        name: 'Presenter Guidelines',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/guideline/presenter_guidelines')
            }
        ]
    },
    {
        path: '/full-program',
        name: 'Full Program',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/full-program')
            }
        ]
    },
    {
        path: '/poster-board',
        name: 'Poster Board Allocations',
        component: () => import('@/views/Context'),
        children: [
            {
                path: '/',
                component: () => import('@/views/program/poster-board')
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history', // 去掉url中的#/
    routes,
})

export default router
